<div class="relative my-2">
    <input [id]="'input'+placeholder" autocomplete="off" [type]="type" #controlingElement [disabled]="isDisabled"
        class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 rounded-lg bg-gray-50"
        [ngClass]="{'border-cyan-500': formStatus==='VALID','border-red-500': formStatus==='INVALID'}"
        [placeholder]="placeholder" (input)="onInputChange($event)" (blur)="onBlur()" [value]="value"
        [attr.aria-label]="placeholder" />
    <label [for]="'input'+placeholder"
        class="absolute left-1  -top-5 {{placeholderColor}} text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:{{placeholderColor}} peer-focus:text-sm unselectable"
        [ngClass]="{'{{placeholderColor}} peer-focus:{{placeholderColor}}': formStatus==='VALID' || 'PENDING','text-red-500 peer-focus:text-red-500': formStatus==='INVALID'}">
        {{placeholder}}
        <span *ngIf="hasValidation" class="text-red-500">*</span>
    </label>
    <ng-icon class="absolute top-2.5 inset-y-0"
    [ngClass]="{'border-cyan-500': formStatus==='VALID','border-red-500': formStatus==='INVALID'}"
    size="20" *ngIf="icon" [svg]="icon" />
</div>