import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { Driver } from '../dtos/driver';

@Injectable({
    providedIn: 'root'
})
export class DriverService {
    private apiUrl = `${environment.apiUrl}/driver`;

    constructor(private http: HttpClient) { }

    getDrivers = (model?: any): Observable<any> => this.http.post<any>(`${this.apiUrl}/get-school-drivers`, model).pipe(take(1), map(d => d?.data! || { data: [], total: 0 }));
    getDriversExcel = (model?: any): Observable<any> => this.getDrivers({ ...model, take: 10000, skip: 0 });

    getDriversDDL = (): Observable<any> => this.getDrivers({ take: 10000 }).pipe(map(i => i.data.map((d: any) => ({ name: `${d.firstName} ${d.lastName}`, id: d.id }))))

    getDriversAccounts = (): Observable<any[]> => this.http.get<any[]>(`${this.apiUrl}/DriversAccounts`).pipe(take(1));
    //todo give them appropriate implementation
    getDriversBlueprints = (): Observable<any[]> => this.http.get<any[]>(`${this.apiUrl}/DriversBlueprints`).pipe(take(1));

    getLocations = (): Observable<Driver[]> => this.http.get<any[]>(`${this.apiUrl}/Locations`).pipe(take(1));

    createDriver = (driver: any): any => this.http.post<any>(`${this.apiUrl}/create`, driver).pipe(take(1));
    updateDriver = (driver: any) => this.http.put<any>(`${this.apiUrl}/update`, driver).pipe(take(1));
    updateDriverActivation = (driverId: string, active: boolean) => this.http.patch<any>(`${this.apiUrl}/activation/${driverId}/${active}`, null).pipe(take(1));
    deleteDriver = (driverId: string) => this.http.delete<any>(`${this.apiUrl}/delete/${driverId}`).pipe(take(1));
    resetPassword = (driverId: string) => this.http.patch<any>(`${this.apiUrl}/reset-default-password/${driverId}`, null).pipe(take(1));
}
