import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  direction = ''
  position = 'toast-top-right'
  constructor(private toastr: ToastrService, translate: TranslateService) {
    translate.onLangChange.subscribe(res => { this.direction = (res.lang == 'ar') ? 'ngx-toastr-rtl' : ''; this.position = (res.lang == 'ar') ? 'toast-top-left' : 'toast-top-right' })
  }

  private getDefaultOptions(timeout: number = 3000, progressBar: boolean = true, closeButton: boolean = true, tapToDismiss: boolean = true): Partial<IndividualConfig<any>> {
    return {
      timeOut: timeout,
      progressBar,
      closeButton,
      tapToDismiss,
      positionClass: this.position,
      toastClass: `ngx-toastr ${this.direction}`,
    };
  }

  success(
    title: string = 'Success',
    body: string = 'Operation completed successfully',
    timeout: number = 3000,
    progressBar: boolean = true,
    closeButton: boolean = true,
    tapToDismiss: boolean = true
  ) {
    const options = this.getDefaultOptions(timeout, progressBar, closeButton, tapToDismiss);
    this.toastr.success(body, title, options);
  }

  info(
    title: string = 'Information',
    body: string = 'Here is some information',
    timeout: number = 3000,
    progressBar: boolean = true,
    closeButton: boolean = true,
    tapToDismiss: boolean = true
  ) {
    const options = this.getDefaultOptions(timeout, progressBar, closeButton, tapToDismiss);
    this.toastr.info(body, title, options);
  }

  warn(
    title: string = 'Warning',
    body: string = 'This is a warning',
    timeout: number = 3000,
    progressBar: boolean = true,
    closeButton: boolean = true,
    tapToDismiss: boolean = true
  ) {
    const options = this.getDefaultOptions(timeout, progressBar, closeButton, tapToDismiss);
    this.toastr.warning(body, title, options);
  }

  error(
    title: string = 'Error',
    body: string = 'An error occurred',
    timeout: number = 3000,
    progressBar: boolean = true,
    closeButton: boolean = true,
    tapToDismiss: boolean = true
  ) {
    const options = this.getDefaultOptions(timeout, progressBar, closeButton, tapToDismiss);
    this.toastr.error(body, title, options);
  }

  clearToasts() {
    this.toastr.clear(); // Clears all toasts
  }
}
