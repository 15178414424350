import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../dtos/config-types';

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    private darkModeSubject = new BehaviorSubject<boolean>(this.getDarkModeFromLocalStorage());
    private languageSubject = new BehaviorSubject<Languages>(this.getLanguageFromLocalStorage() || 'en');

    darkMode$ = this.darkModeSubject.asObservable();
    language$ = this.languageSubject.asObservable();

    constructor(private _translate: TranslateService) {
        // Initialize the translation service with available languages
        _translate.addLangs(['en', 'ar']);
        _translate.setDefaultLang('en');

        // Set the default language based on the stored value
        this._translate.use(this.languageSubject.value);
    }

    toggleDarkMode() {
        this.darkMode$.pipe(take(1)).subscribe(isDarkMode => {
            this.darkModeSubject.next(!isDarkMode);
        });
    }

    setLanguage(language: Languages) {
        this.languageSubject.next(language);
        this._translate.use(language);
        this.setLanguageToLocalStorage(language);
        this.setLanguageClass(language == 'ar' ? 'rtl' : 'ltr');
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    private getDarkModeFromLocalStorage(): boolean {
        return JSON.parse(localStorage.getItem('darkMode') || 'false');
    }

    private setDarkModeToLocalStorage(isDarkMode: boolean) {
        localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    }

    private setLanguageClass(dir: 'ltr' | 'rtl' = 'ltr') {
        document.documentElement.setAttribute('dir', dir);
    }

    private getLanguageFromLocalStorage(): Languages {
        if (!localStorage.getItem('language')) {
            const browserLang = this._translate.getBrowserLang()
            if (browserLang?.match(/en|ar/)) {
                const lang = browserLang as Languages
                lang === 'ar' && this.setLanguageClass('rtl')
                return lang;
            }
            return 'en'
        }
        else {
            const lang = (localStorage.getItem('language') || 'en') as Languages
            lang === 'ar' && this.setLanguageClass('rtl')
            return lang;
        }
    }

    private setLanguageToLocalStorage(language: Languages) {
        localStorage.setItem('language', language);
    }


}
