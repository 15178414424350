import { AfterViewInit, Component, ElementRef, forwardRef, Input, Optional, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ControlContainer, FormGroup, FormControlStatus } from '@angular/forms';

@Component({
  selector: 'sash-input',
  templateUrl: './sash-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SashInputComponent),
    multi: true
  }]
})
export class SashInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input({ required: true }) placeholder: string = '';
  @Input() type: string = 'text';
  @Input() icon?: string
  @Input() placeholderColor: string = 'text-cyan-600'
  @ViewChild('controlingElement', { static: false }) controlElement!: ElementRef;
  value: any = '';
  hasValidation: boolean = false;

  formStatus: FormControlStatus = 'PENDING'

  onChange: (value: any) => void = () => { };
  onTouched: () => void = () => { };
  isDisabled: boolean = false;

  constructor(@Optional() private controlContainer: ControlContainer) { }

  ngAfterViewInit(): void {
    try {
      if (this.controlElement) {
        const controlName = this.controlElement.nativeElement.parentElement.parentElement.getAttribute('formControlName') || '';
        if (controlName) {
          const formGroup = this.controlContainer?.control as FormGroup;
          const formControlElement = formGroup.controls[controlName];
          formControlElement?.statusChanges.subscribe(state => { this.formStatus = state });

          this.hasValidation = formControlElement?.hasValidator(Validators.required);
        }
      }
    } catch (error) {

    }

  }


  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.controlElement) {
      this.controlElement.nativeElement.disabled = this.isDisabled;
    }
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
  }

  onBlur(): void {
    this.onTouched();
  }

}
