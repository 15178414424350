import { Component, OnInit } from '@angular/core';
import { heroBars3Solid, heroArrowLeftStartOnRectangleSolid, heroGlobeEuropeAfricaSolid } from '@ng-icons/heroicons/solid';
import { SystemService, AuthService } from '../services';
import { Languages } from '../dtos/config-types';
import { NavMenu } from './menu';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: [`.activated{@apply bg-gray-500 font-bold rounded-xl }`]

})
export class NavBarComponent implements OnInit {
  isMinimal = false

  icons = {
    bars: heroBars3Solid,
    lang:heroGlobeEuropeAfricaSolid,
    logout: heroArrowLeftStartOnRectangleSolid
  }

  menu: any[];
  constructor(public systemService: SystemService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(res =>{
      this.menu = NavMenu[res.userCategory] || []
    })
  }

  toggleSidebar = () => this.isMinimal = !this.isMinimal

  toggleLanguage = (lang: Languages) => this.systemService.setLanguage(lang == 'ar' ? 'en' : 'ar')

  logout = () => this.authService.logout();
}
