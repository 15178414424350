import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class BlueprintService {
    private apiUrl = `${environment.apiUrl}/blueprint`;

    constructor(private http: HttpClient, private translateService: TranslateService, private datePipe: DatePipe) { }

    //use only for table 
    getBlueprints = (model?: any): Observable<any> => this.http.post<any>(`${this.apiUrl}/get-school-blueprints`, model)
        .pipe(take(1), map(d =>
            d?.data ? ({
                ...d.data,
                data: d.data.data.map((el: any) => ({
                    ...el,
                    areaName: el.schoolCoveredAreas[this.translateService.currentLang == 'ar' ? 'nameAr' : 'nameEn'],
                    driverName: el.driverAccounts.firstName + ' ' + el.driverAccounts.lastName,
                    startTime: this._convertToUtctime(el.tripStartTime),
                    endTime: this._convertToUtctime(el.tripEndTime)
                }))
            })
                : { data: [], total: 0 }
        ));

    getBlueprintsExcel = (model?: any): Observable<any> => this.getBlueprints({ ...model, skip: 0, take: 1000 })

    create = (data: any) => this.http.post(`${this.apiUrl}/create`, data).pipe(take(1))

    update = (data: any) => this.http.put(`${this.apiUrl}/update`, data).pipe(take(1))

    delete = (id: string | number): Observable<any>  => this.http.delete(`${this.apiUrl}/delete/${id}`).pipe(take(1))

    private _convertToUtctime(time: string) {
        const today = new Date();
        const datePart = today.toISOString().split("T")[0];
        const utcDateTimeString = `${datePart}T${time}Z`;
        return this.datePipe.transform(new Date(utcDateTimeString), 'hh:mm');
    }
}
