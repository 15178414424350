<div class="flex flex-col bg-gradient-to-b from-sky-900 to-sky-400 h-full transition-all"
    [ngClass]="[isMinimal?'w-16':'w-64', ((systemService.language$|async)=='en')?'rounded-r-2xl':'rounded-l-2xl']">

    <div class="flex flex-col flex-1 overflow-y-auto">
        <nav class="no-scrollbar flex flex-col flex-1 overflow-y-auto  px-2 py-4 gap-y-5 ">

            <div class="p-2">
                <sidebar-item [isMinimal]="isMinimal" name="SASH" [icon]="icons.bars"
                    (clicked)="toggleSidebar()"></sidebar-item>
            </div>

            <div class="flex flex-col flex-1 gap-2 p-2 gap-y-3">
                <ng-container *ngFor="let t of menu">
                    <sidebar-item [isMinimal]="isMinimal" [name]="t.name" [routerLink]="['/'+t.route]"
                        routerLinkActive="activated" [icon]="t.icon">
                    </sidebar-item>
                </ng-container>


            </div>
            <div class="flex flex-col justify-end">
                <div class="flex justify-center" *ngIf="systemService.language$|async as language">
                    <button
                        class="flex rounded-3xl items-center space-x-2 px-4 py-2 bg-stone-700  hover:bg-stone-500 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                        (click)="toggleLanguage(language)">
                        <ng-icon [svg]="icons.lang"></ng-icon>
                        <span *ngIf="!isMinimal">{{language=="en"?'عربي':"English"}}</span>
                    </button>

                </div>
                <div class="p-2">
                    <sidebar-item [isMinimal]="isMinimal" name="LOGOUT" [icon]="icons.logout"
                        (clicked)="logout()"></sidebar-item>
                </div>
            </div>
        </nav>
    </div>
</div>