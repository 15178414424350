import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NavBarModule } from './nav-bar/nav-bar.module';
import { SashInputComponent } from './sash-input/sash-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SvgRendererComponent } from './components/svg-renderer/svg-renderer.component';
import { ButtonComponent } from './components/button/button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgIconsModule } from '@ng-icons/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [SashInputComponent, SvgRendererComponent, ButtonComponent],
  imports: [
    CommonModule,
    NavBarModule,
    ReactiveFormsModule,
    TranslateModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    AngularSvgIconModule,
    NgIconsModule.withIcons({}),
  ],
  exports: [
    CommonModule,
    NavBarModule,
    SashInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    SvgRendererComponent,
    ButtonComponent,
  ],
  providers: [DatePipe, DialogService],
})
export class SharedModule { }
