import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Statistics } from '../dtos/Statistics';
import { RideChartData } from '../dtos/RideChartData';
import { LocationChartData } from '../dtos/LocationChartData';
import { StudentsChartData } from '../dtos/StudentsChartData';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private apiUrl = `${environment.apiUrl}/school`;

    constructor(private http: HttpClient, private translateService: TranslateService) { }

    getStatistics = (): Observable<Statistics> => this.http.get<Statistics>(`${this.apiUrl}/get-statistics-card`).pipe(map((a: any) => a.success ? a.data : {}));
    getRideChartData = (): Observable<RideChartData> => this.http.get<RideChartData>(`${this.apiUrl}/get-ride-charts-data`).pipe(map((a: any) => a.success ? a.data : {}));
    getLocationChartData = (): Observable<LocationChartData> => this.http.get<LocationChartData>(`${this.apiUrl}/get-locations-charts-data`).pipe(map((a: any) => a.success ? a.data : {}));
    getStudentsChartData = (): Observable<StudentsChartData> => this.http.get<StudentsChartData>(`${this.apiUrl}/get-students-charts-data`).pipe(map((a: any) => a.success ? a.data : {}));
}
