import { heroHomeSolid, heroUserSolid, heroGlobeAsiaAustraliaSolid, heroUserGroupSolid, heroTruckSolid, heroMapPinSolid } from "@ng-icons/heroicons/solid";

export const NavMenu: Record<string, any> = {
    SchoolAdmin: [
        {
            name: 'HOME',
            route: "route",
            icon: heroHomeSolid
        },
        {
            name: "DRIVER",
            route: "driver",
            icon: heroTruckSolid
        },
        {
            name: "STUDENTS",
            route: "student",
            icon: heroUserGroupSolid
        },
        {
            name: "LOCATIONS",
            route: "locations",
            icon: heroMapPinSolid
        },
        {
            name: "BLUEPRINTSETUP",
            route: "driver-blueprints",
            icon: heroGlobeAsiaAustraliaSolid
        },
        // {
        //     name: "SETTINGS",
        //     route: "settings",
        //     icon: heroCog6ToothSolid
        // },
    ],
    SchoolSuperAdmin: [
        {
            name: 'HOME',
            route: "route",
            icon: heroHomeSolid
        },
        {
            name: "DRIVER",
            route: "driver",
            icon: heroTruckSolid
        },
        {
            name: "STUDENTS",
            route: "student",
            icon: heroUserGroupSolid
        },
        {
            name: "LOCATIONS",
            route: "locations",
            icon: heroMapPinSolid
        },
        {
            name: "BLUEPRINTSETUP",
            route: "driver-blueprints",
            icon: heroGlobeAsiaAustraliaSolid
        },
        {
            name: "USERMANAGEMENT",
            route: "user-manager",
            icon: heroUserSolid
        },
    ]
}