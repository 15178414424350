import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthResponse } from '../dtos/auth-response';
import { Router } from '@angular/router';



@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = `${environment.apiUrl}/authentication/login`;
    private localKeys = {
        authToken: 'authToken',
        refreshToken: 'refreshToken',
        idToken: "idToken",
        user: 'user'
    };
    public refreshTokenInProgress: boolean = false
    private currentUserSubject = new BehaviorSubject<any>(null);

    public currentUser$: Observable<any> = this.currentUserSubject.asObservable();

    constructor(private http: HttpClient,private _router:Router) {
        this.loadStoredUser();
    }

    login({ email, password }: { email: string, password: string }): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(this.apiUrl, { username: email, password })
            .pipe(
                tap(response => this.handleAuthSuccess(response)),
                catchError(this.handleError)
            );
    }

    logout(): void {
        this.removeAuthData();
        this.currentUserSubject.next(null);
        this._router.navigate(['/auth/login'])
    }

    isAuthenticated(): boolean {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    refreshToken(): Observable<AuthResponse> {
        this.refreshTokenInProgress = true
        const refreshToken = localStorage.getItem(this.localKeys.refreshToken);
        const accessToken = localStorage.getItem(this.localKeys.authToken);
        if (!refreshToken) {
            throw new Error('No refresh token available.');
        }
        const refreshUrl = `${environment.apiUrl}/authentication/refresh-token`;
        return this.http.post<AuthResponse>(refreshUrl, { accessToken, refreshToken })
            .pipe(
                tap(response =>  this.handleAuthSuccess(response)),
                catchError(this.handleError)
            );
    }
    getToken(): string | null {
        return localStorage.getItem(this.localKeys.authToken);
    }

    storeToken(token: string): void {
        localStorage.setItem(this.localKeys.authToken, token);
    }

    private storeRefreshToken(refreshToken: string): void {
        localStorage.setItem(this.localKeys.refreshToken, refreshToken);
    }

    private storeIdToken(idToken: string): void {
        localStorage.setItem(this.localKeys.idToken, idToken);
    }

    private storeUserDetails(user: any): void {
        localStorage.setItem(this.localKeys.user, JSON.stringify(user));
        this.currentUserSubject.next(user);
    }
    private storeExpirationToken(expiration: string): void {
        var user = JSON.parse(localStorage.getItem(this.localKeys.user)!)
        user.expiration = expiration
        localStorage.setItem(this.localKeys.user, JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    private removeAuthData(): void {
        localStorage.removeItem(this.localKeys.authToken);
        localStorage.removeItem(this.localKeys.refreshToken);
        localStorage.removeItem(this.localKeys.idToken);
        localStorage.removeItem(this.localKeys.user);
    }

    private handleAuthSuccess(response: AuthResponse): void {
        const { accessToken, refreshToken, idToken, expiration, userId, email, firstName, lastName, needAppUpdate, userCategory, SchoolId } = response.data;
        accessToken && this.storeToken(accessToken);
        refreshToken && this.storeRefreshToken(refreshToken);
        idToken && this.storeIdToken(idToken);
        userId && this.storeUserDetails({
            userId,
            email,
            firstName,
            lastName,
            expiration,
            needAppUpdate,
            userCategory,
            SchoolId
        });
        //to update expiration in refresh token
        if(!userId && expiration){
            this.refreshTokenInProgress = false;
            this.storeExpirationToken(expiration);
        }
    }

    private decodeToken(token: string): any {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    private isTokenExpired(token: string): boolean {
        const decodedToken = this.decodeToken(token);
        return decodedToken ? decodedToken.exp * 1000 < Date.now() : true;
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred', error);
        return throwError(() => new Error('An error occurred. Please try again later.'));
    }

    private loadStoredUser(): void {
        const userJson = localStorage.getItem(this.localKeys.user);
        if (userJson) {
            this.currentUserSubject.next(JSON.parse(userJson));
        }
    }
}