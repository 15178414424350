import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SchoolUserService {
    private apiUrl = `${environment.apiUrl}/school-user`;

    constructor(private http: HttpClient) { }

    getUsers = (model?: any): Observable<any> => this.http.post<any>(`${this.apiUrl}/get`, model).pipe(take(1), map(d => d?.data! || { data: [], total: 0 }));
    getUsersExcel = (model?: any): Observable<any> => this.getUsers({ ...model, take: 10000, skip: 0 });
    createUser = (user: any): any => this.http.post<any>(`${this.apiUrl}/create`, user).pipe(take(1));
    updateUser = (user: any) => this.http.put<any>(`${this.apiUrl}/update`, user).pipe(take(1));
    updateUserActivation = (userId: string, active: boolean) => this.http.patch<any>(`${this.apiUrl}/activation/${userId}/${active}`, null).pipe(take(1));
    deleteUser = (userId: string) => this.http.delete<any>(`${this.apiUrl}/delete/${userId}`).pipe(take(1));
}
