import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../../services';

@Component({
  selector: 'sidebar-item',
  templateUrl: './sidebar-item.component.html',
})
export class SidebarItemComponent {
  @Input(({ required: true })) name: string = "HOME"
  @Input() icon!: string
  @Input() route!: string
  @Input() isMinimal: boolean = true

  @Output() clicked = new EventEmitter()

  constructor(private _router: Router, public systemService: SystemService) { }

  handleClick() {
    this.clicked.emit(this)
    this.route && this._router.navigate([this.route])
  }
}
