import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SchoolCoveredAreaService {
    private apiUrl = `${environment.apiUrl}/school-covered-location`;

    constructor(private http: HttpClient, private translateService: TranslateService) { }

    getSchoolCoveredAreas = (model?: any): Observable<any> => this.http.post<any>(`${this.apiUrl}/get`, model).pipe(take(1), map(d => d?.data?.data! || []));
    getSchoolCoveredAreasExcel = (model?: any): Observable<any> => this.getSchoolCoveredAreas({ ...model, skip: 0, take: 10000 })
    getSchoolCoveredAreasDDL = () => this.getSchoolCoveredAreas({ take: 10000 }).pipe(map((a: any) => a.map((i: any) => ({ id: i.id, name: this.translateService.currentLang === 'ar' ? i.nameAr : i.nameEn }))));
    getAreasDllOptions = (): Observable<any> => this.http.get<any>(`${environment.apiUrl}/area/get-dll-options`).pipe(map((a: any) =>a.success ? a.data.map((i: any) => ({ id: i.id, name: this.translateService.currentLang === 'ar' ? i.nameAr : i.nameEn })): []));
    
    create = (model: any): any => this.http.post<any>(`${this.apiUrl}/create`, model).pipe(take(1));
    update = (model: any) => this.http.put<any>(`${this.apiUrl}/update`, model).pipe(take(1));
    updateActivation = (id: number, active: boolean) => this.http.patch<any>(`${this.apiUrl}/activation/${id}/${active}`, null).pipe(take(1));
    delete = (id: string) => this.http.delete<any>(`${this.apiUrl}/delete/${id}`).pipe(take(1));

}
