import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class StudentService {
    private apiUrl = `${environment.apiUrl}/student`;

    constructor(private http: HttpClient) { }

    getStudents = (model?: any): Observable<any> =>
        this.http.post<any>(`${this.apiUrl}/get-school-students`, model).pipe(take(1),
            map(d => d?.data! || { data: [], total: 0 }));

   getStudentsByAreaDDL = (areaId: number): Observable<any> =>  this.http.get<any>(`${this.apiUrl}/get-students-by-area/${areaId}`).pipe(take(1), map(d => d.success?  d?.data:[]));

    getStudentsExcel = (model?: any) => this.getStudents({ ...model, skip: 0, take: 100000 })

    createStudent = (student: any): any =>
        this.http.post<any>(`${this.apiUrl}/create`, student).pipe(take(1));

    updateStudent = (student: any) =>
        this.http.put<any>(`${this.apiUrl}/update`, student).pipe(take(1));

    updateStudentActivation = (studentId: string, active: boolean) =>
        this.http.put<any>(`${environment.apiUrl}/school/student/${studentId}/${active}`, null).pipe(take(1));

    deleteStudent = (studentId: string) =>
        this.http.delete<any>(`${this.apiUrl}/delete/${studentId}`).pipe(take(1));

    resetPassword = (studentId: string) =>
         this.http.patch<any>(`${this.apiUrl}/reset-default-password/${studentId}`, null).pipe(take(1));

}
